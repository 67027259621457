import React from "react";
import PropTypes from "prop-types";
import RSelect from "react-select";
import { injectIntl } from "react-intl";

function Select(props) {
  const {
    className,
    value,
    onChange,
    options,
    getOptionLabel,
    getOptionValue,
    filterOption,
    isSearchable,
    placeholder,
    isError,
    size,
    isDisabled,
    isMulti,
    isClearable,
    dropdownIcon,
    isOptionDisabled,
    onInputChange,
    isLoading,
    menuPortalTarget,
    menuPlacement,
    isPaginationSelect,
    intl,
  } = props;

  const color = "#141416";
  const borderColor = isError ? "#d63131" : "#e6e8ec";
  const hoverBorderColor = isError ? "#d63131" : "#b1b5c3";
  const optionBgColor = "#fcfcfd";

  const fontSize = size === "lg" ? "14px" : size === "md" ? "13px" : size === "sm" ? "12px" : "10px";
  const controlPadding = size === "lg" ? "0px 0px" : size === "md" ? "1px 0px" : size === "sm" ? "0" : "0";
  const controlMinHeight = size === "lg" ? "40px" : size === "md" ? "34px" : size === "sm" ? "30px" : "26px";
  const indicatorPadding = size === "lg" ? "8px" : size === "md" ? "6px" : size === "sm" ? "4px" : "2px";

  return (
    <RSelect
      options={options}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      className={className}
      maxMenuHeight={200}
      placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ""}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      filterOption={filterOption}
      isOptionDisabled={isOptionDisabled}
      onInputChange={onInputChange}
      isLoading={isLoading}
      menuPortalTarget={menuPortalTarget}
      menuPlacement={menuPlacement}
      noOptionsMessage={() => intl.formatMessage({ id: "select.noOptions" })}
      loadingMessage={() => intl.formatMessage({ id: "select.loading" })}
      // components={{
      //   DropdownIndicator: (p) => <SelectDropdownIcon selectProps={p} dropdownIcon={dropdownIcon} />,
      // }}
      styles={{
        control: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isDisabled ? "#f4f5f6" : "transparent",
          border: isFocused ? `1px solid ${borderColor}` : `1px solid ${borderColor}`,
          borderRadius: isPaginationSelect ? "25px" : "6px",
          fontSize: fontSize,
          fontWeight: "400",
          boxShadow: isFocused ? "" : "",
          padding: controlPadding,
          minHeight: controlMinHeight,
          "&:hover": {
            borderColor: `${hoverBorderColor}`,
          },
        }),
        input: (base) => ({ ...base, color: "#333333" }),
        indicatorSeparator: (base) => ({
          ...base,
          background: "none",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: indicatorPadding,
        }),
        clearIndicator: (base) => ({
          ...base,
          padding: indicatorPadding,
        }),
        singleValue: (base) => ({
          ...base,
          color: color,
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: optionBgColor,
          zIndex: 5,
        }),
        menuList: (base) => ({
          ...base,
          "::-webkit-scrollbar": {
            width: "9px",
            height: "9px",
            borderRadius: "6px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f4f5f6",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#4c6fff",
            borderRadius: "6px",
          },
        }),
        option: (base, { isFocused, isSelected, isDisabled: isOptionDisabled }) => ({
          ...base,
          cursor: "pointer",
          fontSize: "14px",
          fontWeight: "500",
          background: isSelected || isFocused ? "none" : "none",
          color: isOptionDisabled ? "#b1b5c3" : isSelected || isFocused ? "#4c6fff" : color,
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: "12px",
          color: "#777e90",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
      }}
    />
  );
}

export default injectIntl(Select);

Select.propTypes = {
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.any,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  size: PropTypes.oneOf(["mxs", "sm", "md", "lg"]),
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  filterOption: PropTypes.func,
  dropdownIcon: PropTypes.string,
  isOptionDisabled: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  menuPortalTarget: PropTypes.any,
  menuPlacement: PropTypes.string,
  isPaginationSelect: PropTypes.bool,
};

Select.defaultProps = {
  value: {},
  onChange: () => {},
  className: null,
  isSearchable: false,
  isClearable: false,
  options: [],
  getOptionLabel: () => {},
  getOptionValue: () => {},
  placeholder: "placeholder.select",
  isError: false,
  size: "lg",
  isDisabled: false,
  isMulti: false,
  filterOption: undefined,
  dropdownIcon: "",
  isOptionDisabled: undefined,
  onInputChange: () => {},
  isLoading: false,
  menuPortalTarget: null,
  menuPlacement: "auto",
  isPaginationSelect: false,
};
